import React, { useState, useEffect } from 'react';
import { DoctIconButton, DoctTypography } from '@doct-react/core';
import { DoctDropdownSelect } from '@doct-react/app';
import dayjs from 'dayjs';
import PropyTypes from 'prop-types';
import useQueryHooks from '../../../../hooks/useQueryHooks';
import qs from 'qs';
import ApplicantActionTypes from '../../../../features/InstituteDashboard/Applicants/components/ApplicantActionTypes';
import SubscriptionSubscribePlan from '../../SubscriptionHoverCard/SubscriptionSubscribePlan';
import SubscriptionUpgradeCard from '../../SubscriptionHoverCard/SubscriptionUpgradeCard';
import LoadMore from '../../../../features/RecruiterDashboard/JobListing/components/LoadMore';
import { manageColumnData } from '../../../../helper/helperFunction';

function TableRow({
  column,
  tableColumn,
  collapsibleItem: CollapsibleItem,
  tableCollapsibleData,
  actionMenu: ActionMenu,
  actionButton: ActionButton,
  index,
  tagetedRowIndex,
  setTargetedRowIndex,
  status,
  setSelectedItemId = () => {},
  setIsOpen,
  activeRowIndex: activeRowIndexProps,
  setActiveRowIndex: setActiveRowIndexProps,
  handleRowClickCallback,
  dropdownMenuItemInStatusWithButton,
  dropdownStatusButtonClassesHandler,
  handleButtonStatusChange,
  setStatus,
}) {
  const [refCollepsibleEl, setRefCollepsibleEl] = useState(null);
  const [height, setHeight] = useState(0);
  const [value, setValue] = useState('');
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [isFreePlan, setIsFreePlan] = useState(false);

  const activeRowIndex = activeRowIndexProps || tagetedRowIndex;
  const setActiveRowIndex = setActiveRowIndexProps || setTargetedRowIndex;

  useEffect(() => {
    const columnNew = tableColumn.find((item) => item.key === 'buttonStatus');
    if (columnNew) setValue(column.status);
  }, [tableColumn, column?.status]);
  useEffect(() => {
    if (!setIsOpen) return;
    if (height === 0) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [height]);

  useEffect(() => {
    const elBoundingClientReact = refCollepsibleEl?.getBoundingClientRect();
    setTimeout(() => {
      setHeight(elBoundingClientReact?.height || 0);
    });
  }, [refCollepsibleEl, refCollepsibleEl?.current]);

  useEffect(() => {
    setStatus(column?.status);
    //Note :- Don not uncommite code below lines it makes multiple api call
    // if (column?.status === 'UnRead' && activeRowIndex === index) {
    if (column?.isLimitExceeded || (column?.isFreePlan && column?.isBlur)) {
      setHeight(0);
      setTimeout(() => {
        setActiveRowIndex(null);
      }, 300);
    }
    // }
  }, [column?.status, activeRowIndex]);

  const toggleCollepsible = (index) => {
    if (index) {
      if (activeRowIndex == index) {
        setHeight(0);
        setTimeout(() => {
          setActiveRowIndex(null);
        }, 300);
      }
      setActiveRowIndex(index);
    } else {
      setHeight(0);
      setTimeout(() => {
        setActiveRowIndex(null);
      }, 300);
    }
    if (handleRowClickCallback) handleRowClickCallback(activeRowIndex != index, column);
    return;
  };

  const handleMouseOnHover = () => {
    if (column?.isFreePlan && column?.isBlur) {
      setIsFreePlan(true);
      return;
    }
    if (column?.isLimitExceeded) {
      setIsLimitReached(true);
    }
  };

  const handleMouseLeave = () => {
    if (column?.isFreePlan && column?.isBlur) {
      setIsFreePlan(true);
      return;
    }
    // if (column?.isLimitExceeded) {
    setIsLimitReached(false);
    // }
  };

  if (isLimitReached) {
    return (
      <SubscriptionUpgradeCard
        setIsHovering={column?.isFreePlan && column?.isBlur ? setIsFreePlan : setIsLimitReached}
        limitExceededCount={column?.limitExceededCount}
      />
    );
  }

  if (isFreePlan) {
    return (
      <SubscriptionSubscribePlan
        setIsHovering={column?.isFreePlan && column?.isBlur ? setIsFreePlan : setIsLimitReached}
      />
    );
  }
  function formatDate(date) {
    return dayjs(date).format('DD MMM YYYY');
  }

  function getAppliedDate(column) {
    let appliedDate;

    //Get Different property for institute nad event so use attendeeId for event
    if (column?.attendeeId) {
      appliedDate = column?.orderCreatedOn || null;
    } else if (column?.latestActionOfApplicant?.latestActionDate) {
      appliedDate =
        column?.latestActionOfApplicant?.latestActionDate === null
          ? column?.createdDate
          : column?.latestActionOfApplicant?.latestActionDate;
    }

    return formatDate(appliedDate);
  }
  return (
    <div
      className={`text-grey-100 px-3 bg-white ${status} pb-2 custom-tabel-row-wrapper cursor-pointer ${
        (column?.isFreePlan && column?.isBlur) || column?.isLimitExceeded ? 'text-blur-effect' : ''
      }`}
      onClick={() => {
        if (!column?.isFreePlan || (column?.isFreePlan && !column?.isBlur)) {
          toggleCollepsible(index);
          setSelectedItemId(column?.orderId ? column?.orderId : column?.id ? column?.id : null);
        }
      }}
      onMouseEnter={handleMouseOnHover}
      onMouseLeave={handleMouseLeave}
    >
      <div className="d-flex custom-tabel-row align-items-center position-relative">
        {column?.latestActionOfApplicant?.latestActionDate && column?.status === 'UnRead' && (
          <div className="highlighted-dot position-absolute bg-info"></div>
        )}
        {tableColumn?.map((el, i) => {
          if (el.key == 'action' && activeRowIndex != index) {
            return (
              <span
                onClick={(e) => e.stopPropagation()}
                className="custom-tabel-row-action-menu-column d-inline-flex justify-content-end"
                key={i}
              >
                {ActionMenu && (
                  <ActionMenu
                    column={column}
                    index={index}
                    toggleCollepsibleOnActionClick={toggleCollepsible}
                  />
                )}
              </span>
            );
          }
          if (el.key == 'actionType') {
            return <ApplicantActionTypes column={column} key={i} />;
          }
          if (el.key == 'buttonStatus') {
            return (
              <div className="custom-tabel-row-el my-0 px-2" key={i}>
                {column?.status && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={dropdownStatusButtonClassesHandler(value)}
                  >
                    <DoctDropdownSelect
                      value={value}
                      handleChange={(e) => {
                        e.stopPropagation();
                        handleButtonStatusChange(e.target.value, column);
                        setValue(e.target.value);
                      }}
                      menuItems={dropdownMenuItemInStatusWithButton}
                    />
                  </span>
                )}
              </div>
            );
          }
          return (
            <DoctTypography
              key={el.title}
              variant="textLabel2"
              className={`text-grey-800 custom-tabel-row-el px-2 my-0 text-truncate ${
                el.key?.toLowerCase() || ''
              } ${
                el.key == 'status'
                  ? column[el.key] == 'Created'
                    ? 'pending font-weight-medium'
                    : column[el.key]?.toLowerCase()
                  : ''
              } ${
                el.key == 'memberStatus'
                  ? column[el.key] == 'Active'
                    ? 'active font-weight-bold'
                    : column[el.key]?.toLowerCase()
                  : ''
              } ${
                el.key == 'memberStatus'
                  ? column[el.key] == 'Draft'
                    ? 'draft font-weight-bold'
                    : column[el.key]?.toLowerCase()
                  : ''
              } ${
                el.key == 'memberStatus'
                  ? column[el.key] == 'Inactive'
                    ? 'inactive'
                    : column[el.key]?.toLowerCase()
                  : ''
              } ${
                el.key == 'memberStatus'
                  ? column[el.key] == 'Renewal Pending'
                    ? 'renewalpending'
                    : column[el.key]?.toLowerCase()
                  : ''
              } ${column?.latestActionOfApplicant?.latestActionDate ? 'mt-n2' : ''}`}
            >
              {el.currency && `${column['currency'] || ''} `}
              {el.keySelector && el.keySelector(column)}
              {manageColumnData(el, column)}
            </DoctTypography>
          );
        })}
      </div>
      <DoctTypography
        variant="textLabel2"
        className={`text-grey-600 custom-tabel-row-el px-2 my-0 text-truncate mt-n3 mb-2`}
      >
        Applied {getAppliedDate(column)}
      </DoctTypography>
      <div
        style={{
          height: `auto`,
          transition: 'height 0.3s ease',
          overflow: 'hidden',
        }}
        className="mx-n2"
      >
        {activeRowIndex == index && (
          <>
            <div
              ref={(el) => setRefCollepsibleEl(el)}
              className={`${
                activeRowIndex == index ? 'pt-4' : 'pt-0'
              } px-4 border-radius custom-table-collapsible-bg custom-table-with-action-footer
            position-relative`}
            >
              {CollapsibleItem && (
                <CollapsibleItem
                  column={column}
                  tableCollapsibleData={tableCollapsibleData}
                  activeRowIndex={activeRowIndex}
                />
              )}
              {ActionButton && <ActionButton column={column} />}
              <span
                className="custom-tabel-collapse-btn position-absolute d-inline-flex"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleCollepsible();
                }}
              >
                <DoctIconButton variant="outline" type="secondary" icon="up" size="medium" />
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default function TableRows({
  tableRowData,
  collapsibleItem,
  column,
  setSelectedItemId,
  actionMenu,
  actionButton,
  tableCollapsibleData,
  setTriggredDownload,
  setIsOpen,
  activeRowIndex,
  setActiveRowIndex,
  handleRowClickCallback,
  dropdownMenuItemInStatusWithButton,
  dropdownStatusButtonClassesHandler,
  handleButtonStatusChange,
  setStatus,
}) {
  const query = useQueryHooks();

  const [tagetedRowIndex, setTargetedRowIndex] = useState(null);

  useEffect(() => {
    setTargetedRowIndex(null);
  }, [qs.stringify(query)]);

  const [jobsAllData, setJobsAllData] = useState(20);
  const [jobsData] = useState(0);

  return (
    <>
      {tableRowData?.slice(jobsData, jobsAllData).map((el, index) => (
        <TableRow
          status={el?.status?.toLowerCase() || ''}
          tableColumn={column}
          column={el}
          key={index}
          index={index + 1}
          collapsibleItem={collapsibleItem}
          tableCollapsibleData={tableCollapsibleData}
          actionMenu={actionMenu}
          actionButton={actionButton}
          setSelectedItemId={setSelectedItemId}
          tagetedRowIndex={tagetedRowIndex}
          setTargetedRowIndex={setTargetedRowIndex}
          setTriggredDownload={setTriggredDownload}
          setIsOpen={setIsOpen}
          activeRowIndex={activeRowIndex}
          setActiveRowIndex={setActiveRowIndex}
          handleRowClickCallback={handleRowClickCallback}
          dropdownMenuItemInStatusWithButton={dropdownMenuItemInStatusWithButton}
          dropdownStatusButtonClassesHandler={dropdownStatusButtonClassesHandler}
          handleButtonStatusChange={handleButtonStatusChange}
          setStatus={setStatus}
        />
      ))}
      {jobsAllData < tableRowData.length && (
        <div className="d-flex align-items-center justify-content-center">
          <LoadMore
            tableRowData={tableRowData}
            setJobsAllData={setJobsAllData}
            jobsAllData={jobsAllData}
          />
        </div>
      )}
    </>
  );
}

TableRows.propTypes = {
  column: PropyTypes.array,
  collapsibleItem: PropyTypes.any,
  tableCollapsibleData: PropyTypes.any,
  index: PropyTypes.any,
  setTargetedRowIndex: PropyTypes.any,
  tableColumn: PropyTypes.any,
  setSelectedItemId: PropyTypes.any,
};
