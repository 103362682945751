import React from 'react';
import { useSelector } from 'react-redux';

import { DoctModal } from '@doct-react/app';

import { selectModalTitle } from '../slice/committee.slice';
import { LoaderForModal } from '../../../../shared';

function CommitteeModal({
  children,
  isLoading,
  onSubmitForm,
  handleModalClose,
  isOpen,
  isPrimaryBtnDisable,
  primaryBtnText,
}) {
  const modalTitle = useSelector(selectModalTitle);

  return (
    <DoctModal
      title={modalTitle}
      open={isOpen}
      iconName=""
      handleClose={handleModalClose}
      handlePrimaryButtonClick={onSubmitForm}
      isPrimaryBtnDisable={isPrimaryBtnDisable}
      primaryBtnText={primaryBtnText}
    >
      {isLoading ? <LoaderForModal /> : children}
    </DoctModal>
  );
}

export default CommitteeModal;
