export async function confirmSubscription(storedBundle, id, prepObjectForFormData, token) {
  try {
    if (!storedBundle) {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/${id}/subscription`,
        {
          method: 'POST',
          body: JSON.stringify(prepObjectForFormData),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response?.ok) {
        const data = await response?.clone().json();
        window.location.href = data?.paymentInformation?.redirectUrl;
      }
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/${storedBundle.id}/bundle/${storedBundle.bundleId}/subscription`,
        {
          method: 'POST',
          body: JSON.stringify(prepObjectForFormData),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response?.ok) {
        const data = await response?.clone().json();
        window.location.href = data?.paymentInformation?.redirectUrl;
      }
    }
  } catch (error) {
    throw new Error(error);
  }
}
