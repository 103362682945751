import React from 'react';
import style from '../SubscriptionModal.module.scss';
import { DoctTypography } from '@doct-react/core';

export default function SubscriptionChip({ subscriptionChipText, isExpired }) {
  return (
    <div
      className={`${style['subscription-chip']} ${
        isExpired ? 'bg-grey-400' : 'bg-success'
      }  text-white text-uppercase font-weight-regular`}
    >
      <DoctTypography variant="subtitle3" className="m-0">
        {subscriptionChipText}
      </DoctTypography>
    </div>
  );
}
