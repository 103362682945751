import React, { useEffect, useState } from 'react';
import { DoctButton, DoctCol, DoctTypography, DoctIcon, DoctRow } from '@doct-react/core';
import { useDispatch, useSelector } from 'react-redux';
import { FixedPanel, Toaster } from '../../../../../../shared/ui';
import {
  saveMaxAttendees,
  selectAttendeeValue,
  selectBasicInfoDetails,
  selectCapacityUsed,
  selectCreateEventResponse,
  selectCurrentStep,
  selectShowError,
  selectTicketsDetails,
  selectUpdateEventLoader,
  setCurrentStep,
  setShowError,
} from '../../../createEvent.slice';
import CurrentAndTotalSteps from '../../CurrentAndTotalSteps';
import { StepTitle } from '../../UiHelper';
import stepsName from '../stepsName';
import useFormTickets from './Form.Tickets';
import { ForeignRegistration, IndianRegistration, TicketCategories } from './Components';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EventStatus } from '../../../../../../helper/enums/eventStatus';
import './TicketsStyle.scss';

export default function Tickets() {
  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const BasicInfoDetails = useSelector(selectBasicInfoDetails);
  const apiResponseData = useSelector(selectCreateEventResponse);
  const ticketsDetails = useSelector(selectTicketsDetails);
  const loading = useSelector(selectUpdateEventLoader);
  const [attendeeEdit, setAttendeeEdit] = useState(false);

  const {
    handleFormSubmit,
    inrTickets,
    setInrTickets,
    usdTickets,
    setUsdTickets,
    editAttendeeValue,
    setEditAttendeeValue,
    setIsAttendeevalueChange,
    isAttendeevalueChange,
    registrationType,
    setRegistrationType,
    gstRegistration,
    setGSTRegistration,
    tarrif,
    setTarrif,
    emptyCategories,
    setEmptyCategories,
    isFreeEventTicketEmpty,
    setIsFreeEventTicketEmpty,
    tarrifValidation,
    setTarrifValidation,
    setInrTotalSeatCapacity,
    setUsdTotalSeatCapacity,
    setCheckAmountIsZero,
    checkAmountIsZero,
    setFirstTimeNotRender,
    setIsCapacityValidation,
    isCapacityValidation,
  } = useFormTickets();

  const showError = useSelector(selectShowError);

  const [erorFormSubmit, setErrorFormSubmit] = useState(null);

  const [showTost, setShowTost] = useState(false);
  const [showPaymentType, setShowPaymentType] = useState('');

  const maxAttendees = useSelector(selectAttendeeValue);
  const capacity = useSelector(selectCapacityUsed);

  useEffect(() => {
    if (apiResponseData?.eventPaymentType) {
      setShowPaymentType(apiResponseData?.eventPaymentType);
    }
    if (BasicInfoDetails.registrationType) {
      setShowPaymentType(BasicInfoDetails.registrationType);
    }
  }, [apiResponseData, BasicInfoDetails]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowError(false));
      setEmptyCategories(false);
    }, 2000);
  }, [showError]);

  useEffect(() => {
    if (apiResponseData?.invoiceDetail?.isGSTRegistration === true) {
      setGSTRegistration('true');
    } else if (ticketsDetails.invoiceDetail?.isGSTRegistration === 'true') {
      setGSTRegistration('true');
    }

    if (apiResponseData?.tariffTaxation) {
      setTarrif(apiResponseData?.tariffTaxation);
    } else if (ticketsDetails.tariffTaxation) {
      setTarrif(ticketsDetails.tariffTaxation);
    }
  }, [apiResponseData, ticketsDetails]);

  useEffect(() => {
    if (Object.keys(apiResponseData).length) {
      const usdArray = apiResponseData?.tickets?.filter(({ currency }) => currency == 'USD') || [];
      if (usdArray.length) {
        setRegistrationType('Yes');
      }
    }
    if (Object.keys(ticketsDetails).length) {
      const usdArray = ticketsDetails?.tickets?.filter(({ Currency }) => Currency == 'USD') || [];
      if (usdArray.length) {
        setRegistrationType('Yes');
      }
    }
  }, [Object.keys(apiResponseData).length, Object.keys(ticketsDetails).length]);

  useEffect(() => {
    if (registrationType == 'No') {
      setUsdTickets([]);
    }
  }, [registrationType]);

  const onTostCloseHandler = () => {
    setShowTost(false);
    setErrorFormSubmit(null);
    setEmptyCategories(false);
    setTarrifValidation(false);
    setIsFreeEventTicketEmpty(false);
    setCheckAmountIsZero(false);
    setIsCapacityValidation(false);
  };

  useEffect(() => {
    if (
      isFreeEventTicketEmpty ||
      tarrifValidation ||
      showTost ||
      checkAmountIsZero ||
      emptyCategories ||
      isCapacityValidation
    ) {
      setTimeout(() => {
        onTostCloseHandler();
      }, 2000);
    }
  }, [isFreeEventTicketEmpty, tarrifValidation, showTost, checkAmountIsZero]);

  useEffect(() => {
    if (isAttendeevalueChange) {
      setIsAttendeevalueChange(false);
      dispatch(saveMaxAttendees(editAttendeeValue));
    }
  }, [isAttendeevalueChange]);

  function ToasterTextMessage() {
    if (isFreeEventTicketEmpty) {
      return 'must select tickets';
    } else if (tarrifValidation) {
      return 'Please Select Tarrif and Taxation';
    } else if (checkAmountIsZero) {
      return 'Amount must not be 0 in paid event ticket';
    } else if (emptyCategories) {
      return 'must select one category';
    } else if (erorFormSubmit?.Title || showError) {
      return 'Oops! something went wrong';
    } else if (isCapacityValidation) {
      return 'Number can not be less than of total ticket sold / seat capacity given.';
    }
  }

  const canShowToast =
    isFreeEventTicketEmpty ||
    showError ||
    checkAmountIsZero ||
    tarrifValidation ||
    emptyCategories ||
    isCapacityValidation;

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <DoctCol sm={10} className="mx-auto mb-5">
          {canShowToast && (
            <div className="position-fixed tost-container">
              <Toaster
                variant={'error'}
                text={ToasterTextMessage()}
                onPressedClose={onTostCloseHandler}
              />
            </div>
          )}
          <DoctRow>
            <DoctCol xs={9} className="mx-auto mb-3">
              <StepTitle className="text-center">{stepsName.tickets.label}</StepTitle>
              {showPaymentType == 'Paid' && (
                <>
                  <DoctTypography variant="subtitle2" fontWeight="bold" className="text-grey-800">
                    Does GST applicable for this event?
                  </DoctTypography>
                  <div className="border-radius mb-2">
                    <RadioGroup
                      aria-labelledby="registrationType"
                      name="registrationType"
                      value={gstRegistration}
                      onChange={(e) => {
                        setGSTRegistration(e.target.value);
                      }}
                    >
                      <div className="d-inline-flex">
                        <div
                          className={
                            gstRegistration == 'true'
                              ? 'background_white px-3 mr-2'
                              : 'background_grey mr-2 px-3'
                          }
                        >
                          <FormControlLabel
                            name="true"
                            value="true"
                            control={<Radio />}
                            label="Yes"
                            className="text-grey-800"
                            defaultChecked={true}
                            disabled={
                              apiResponseData.status == EventStatus.ACTIVE ||
                              apiResponseData.status == EventStatus.ACTIVATIONREQUESTED
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div
                          className={
                            gstRegistration == 'false'
                              ? 'background_white px-3 mr-2'
                              : 'background_grey mr-2 px-3'
                          }
                        >
                          <FormControlLabel
                            name="false"
                            value="false"
                            control={<Radio onChange={() => setTarrif(null)} />}
                            label="No"
                            className="text-grey-800"
                            disabled={
                              apiResponseData.status == EventStatus.ACTIVE ||
                              apiResponseData.status == EventStatus.ACTIVATIONREQUESTED
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </RadioGroup>
                  </div>
                  <DoctTypography variant="subtitle2" fontWeight="bold" className="text-grey-800">
                    Tariff & Taxation
                  </DoctTypography>
                  <div className="border-radius mb-2">
                    <RadioGroup
                      aria-labelledby="tariff"
                      name="tariff"
                      value={tarrif}
                      onChange={(e) => {
                        setTarrif(e.target.value);
                      }}
                    >
                      <div className="d-inline-flex">
                        <div
                          className={
                            tarrif == 'IncludingAllTaxes'
                              ? 'background_white px-3 mr-2'
                              : 'background_grey mr-2 px-3'
                          }
                        >
                          <FormControlLabel
                            name="IncludingAllTaxes"
                            value="IncludingAllTaxes"
                            control={<Radio />}
                            label="Including All Taxes"
                            className="text-grey-800"
                            defaultChecked={true}
                            disabled={
                              apiResponseData.status == EventStatus.ACTIVE ||
                              apiResponseData.status == EventStatus.ACTIVATIONREQUESTED ||
                              gstRegistration == 'false'
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div
                          className={
                            tarrif == 'ExcludingAllTaxes'
                              ? 'background_white px-3 mr-2'
                              : 'background_grey mr-2 px-3'
                          }
                        >
                          <FormControlLabel
                            name="ExcludingAllTaxes"
                            value="ExcludingAllTaxes"
                            control={<Radio />}
                            label="Excluding All Taxes"
                            className="text-grey-800"
                            disabled={
                              apiResponseData.status == EventStatus.ACTIVE ||
                              apiResponseData.status == EventStatus.ACTIVATIONREQUESTED ||
                              gstRegistration == 'false'
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </RadioGroup>
                  </div>
                </>
              )}
            </DoctCol>
          </DoctRow>

          <div className="d-flex justify-content-between align-items-center bg-white border-radius px-4 py-3 total_atendees_section">
            <div className="d-flex">
              <DoctIcon name="membersOutline" fill="#00A0C0" className="members_icon mb-3 mt-3" />
              <DoctTypography
                className="text-grey-800 mx-2 mt-3"
                fontWeight="regular"
                variant="textLabel1"
              >
                Total attendees limit for this Event
              </DoctTypography>
            </div>

            <div className="d-flex">
              {attendeeEdit ? (
                <input
                  className="border-radius border-light numbers_adding px-1 py-1 text-grey-800 bg-grey-200 mt-3"
                  name="maxAttendee"
                  value={editAttendeeValue}
                  onChange={(e) => {
                    setEditAttendeeValue(e.target.value);
                  }}
                />
              ) : (
                <DoctTypography
                  className="border-radius numbers_adding px-4 py-1 text-grey-800 bg-grey-200 mt-3"
                  fontWeight="medium"
                  variant="textLabel1"
                >
                  {editAttendeeValue ? editAttendeeValue : maxAttendees}
                </DoctTypography>
              )}
              {attendeeEdit ? (
                <DoctButton
                  variant="text"
                  type="semantic-success"
                  text="Save"
                  className="mt-2"
                  onButtonClickHandler={(e) => {
                    e.preventDefault();
                    if (editAttendeeValue < capacity) {
                      setIsCapacityValidation(true);
                    } else {
                      setIsCapacityValidation(false);
                      setAttendeeEdit(false);
                      setIsAttendeevalueChange(true);
                    }
                  }}
                />
              ) : (
                <DoctButton
                  variant="text"
                  type="semantic-info"
                  text="Edit"
                  className="mt-2"
                  onButtonClickHandler={(e) => {
                    e.preventDefault();
                    setAttendeeEdit(true);
                  }}
                  disabled={
                    apiResponseData?.numberOfGathering == 'Unlimited' ||
                    BasicInfoDetails?.numberOfGathering == 'Unlimited'
                      ? true
                      : false
                  }
                />
              )}
            </div>
          </div>
          {BasicInfoDetails?.numberOfGathering != 'Unlimited' && (
            <div className="d-flex justify-content-between align-items-center border-radius bg-grey-100 px-4 py-3 total_atendees_section">
              <DoctTypography
                className="text-grey-800 mx-2 mt-3"
                fontWeight="regular"
                variant="textLabel1"
              >
                Remaining Seats
              </DoctTypography>
              <DoctTypography
                className="text-grey-800 mx-2 mt-3 mr-5 pr-5"
                fontWeight="medium"
                variant="textLabel1"
              >
                {+maxAttendees - +capacity}
              </DoctTypography>
            </div>
          )}

          <div className="mt-3">
            <TicketCategories setFirstTimeNotRender={setFirstTimeNotRender} />
          </div>
          <IndianRegistration
            setInrTickets={setInrTickets}
            inrTickets={inrTickets}
            tarrif={tarrif}
            setTotalSeatCapacity={setInrTotalSeatCapacity}
          />
          <DoctRow>
            <DoctCol xs={12} className="mx-auto mb-3">
              <DoctTypography
                variant="subtitle2"
                className="mt-3 radio_btn_text"
                fontWeight="normal"
              >
                Do you want Foreign Registration for your event?
              </DoctTypography>
              <div className="border-radius">
                <RadioGroup
                  aria-labelledby="registrationType"
                  name="registrationType"
                  value={registrationType}
                  onChange={(e) => {
                    setRegistrationType(e.target.value);
                  }}
                >
                  <div className="align-item-center d-flex justify-content-center">
                    <div className="d-inline-flex w-75">
                      <div
                        className={
                          registrationType == 'Yes'
                            ? 'background_white px-3 mr-2'
                            : 'background_grey mr-2 px-3'
                        }
                      >
                        <FormControlLabel
                          name="Yes"
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                          className="text-grey-800"
                          defaultChecked={true}
                        />
                      </div>
                      <div
                        className={
                          registrationType == 'No'
                            ? 'background_white px-3 mr-2'
                            : 'background_grey mr-2 px-3'
                        }
                      >
                        <FormControlLabel
                          name="No"
                          value="No"
                          control={<Radio />}
                          label="No"
                          className="text-grey-800"
                        />
                      </div>
                    </div>
                  </div>
                </RadioGroup>
              </div>
            </DoctCol>
          </DoctRow>
          {registrationType == 'Yes' && (
            <ForeignRegistration
              usdTickets={usdTickets}
              setUsdTickets={setUsdTickets}
              tarrif={tarrif}
              setTotalSeatCapacity={setUsdTotalSeatCapacity}
            />
          )}
        </DoctCol>
      </form>
      <FixedPanel
        container
        className="backdrop-filter"
        contentClassName="d-flex align-items-center py-12px"
      >
        <CurrentAndTotalSteps />
        <DoctButton
          text="Back"
          variant="outline"
          className="mr-2"
          onButtonClickHandler={() => {
            dispatch(setCurrentStep(currentStep - 1));
          }}
        />
        <DoctButton
          disabled={loading}
          text="Save & Next"
          className=""
          onButtonClickHandler={() => {
            handleFormSubmit();
          }}
        />
      </FixedPanel>
    </>
  );
}
