import React from 'react';
import { DoctAnimationLoading } from '@doct-react/app';
import { DoctContainer, DoctIcon, DoctTypography } from '@doct-react/core';
import useSendSubscription from '../hooks/useSendSubscription';
import CommonPackageDetailCard from '../CommonPackageDetailCard/CommonPackageDetailCard';
import Accordion from '../CommonAccordion/Accordian';
import CommonBillingInfoCard from '../CommonBillingInfoCard/CommonBillingInfoCard';
import { useGetAdditionalInformationQuery, useGetOrderDetailsQuery } from '../API/subscriptionApi';
import { useParams } from 'react-router-dom';
import FooterCopyRight from '../../../layout/components/Footer.CopyRight';
import { EnterpriseFormLayout } from '../../../layout';
import axios from 'axios';
import dayjs from 'dayjs';
import * as ROUTE from '../../../routes/constant';
import style from '../SubscriptionModal.module.scss';

const ConfirmationScreen = () => {
  const { id } = useParams();
  const { navigate } = useSendSubscription();
  const { data, isLoading } = useGetOrderDetailsQuery(id);
  const { data: billingInfo } = useGetAdditionalInformationQuery();

  if (isLoading) {
    return <DoctAnimationLoading />;
  }

  const { subscriberOrderDTO, subscriberPlanHistory } = data || {};
  const packageStartDate = dayjs(subscriberOrderDTO?.subscriber?.startDate); // Use dayjs to parse the date
  const formattedDate = packageStartDate.format('DD MMMM YYYY'); // Format the date
  const packageEndDate = dayjs(subscriberOrderDTO?.subscriber?.expiryDate); // Use dayjs to parse the expiry date
  const formattedExpiryDate = packageEndDate.format('DD MMMM YYYY');

  function showSecondaryHeaderBtnBoolean() {
    if (subscriberOrderDTO?.orderStatus === 'Confirmed') {
      return true;
    } else if (subscriberOrderDTO?.orderStatus === 'Created') {
      return false;
    } else if (subscriberOrderDTO?.orderStatus === 'Active') {
      return false;
    } else if (subscriberOrderDTO?.orderStatus === 'Expired') {
      return false;
    } else if (subscriberOrderDTO?.orderStatus === 'Failed') {
      return false;
    }
  }
  function showInvoiceBoolean() {
    if (
      subscriberOrderDTO?.orderStatus === 'Confirmed' &&
      typeof billingInfo === 'object' &&
      billingInfo !== null &&
      subscriberOrderDTO?.type !== 'Free' &&
      subscriberOrderDTO?.subscriberBillingInformation !== null &&
      Object.keys(billingInfo).length != 0
    ) {
      return true;
    }
    return false;
  }

  function getSubscriptionText(subscriberPlanHistory) {
    if (parseInt(subscriberPlanHistory?.duration) < 0) {
      return 'Lifetime';
    } else if (subscriberPlanHistory?.duration === '12 Month') {
      return '1 Year Access';
    } else {
      return `${subscriberPlanHistory?.duration} ${subscriberPlanHistory?.durationType}`;
    }
  }

  const subscriptionText = getSubscriptionText(subscriberPlanHistory);

  let durationOfPlan;

  if (subscriberOrderDTO?.type !== 'Free') {
    durationOfPlan = `${formattedDate} - ${formattedExpiryDate}`;
  } else if (subscriptionText === 'Lifetime') {
    durationOfPlan = `${formattedDate}`;
  } else if (formattedDate && formattedExpiryDate) {
    durationOfPlan = `${formattedDate} - ${formattedExpiryDate}`;
  } else if (formattedDate) {
    durationOfPlan = `${formattedDate}`;
  } else {
    durationOfPlan = '';
  }

  return (
    <>
      <EnterpriseFormLayout
        formTitle="Order Details"
        iconName="arrowBack"
        isConfirmationScreen={true}
        isFooterShow={`${subscriberOrderDTO?.orderStatus === 'Confirmed' && ''}`}
        formPrimaryBtnText={`${
          (subscriberOrderDTO?.orderStatus === 'Created' && 'Payment Failed') || 'Payment Failed'
        }`}
        isAdding={false}
        primaryButtonType={`${subscriberOrderDTO?.orderStatus === 'Created' && 'semantic-success'}`}
        closeHandler={() => {
          navigate(
            `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}`,
          );
        }}
        handleSubmit={() => {
          navigate('/');
        }}
        formHeaderSecondaryButtonClickHandler={() => {
          axios
            .get(
              `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/${subscriberOrderDTO?.orderNumber}/getinvoice`,
            )
            .then((res) => {
              window.open(res?.data.invoiceUrl, '_blank');
            });
        }}
        showSecondaryHeaderBtn={showSecondaryHeaderBtnBoolean()}
        formSecondaryBtnText={showInvoiceBoolean() ? 'Invoice' : ''}
      >
        <DoctContainer>
          <CommonPackageDetailCard
            icon={
              subscriberOrderDTO?.orderStatus === 'Confirmed' ? (
                ''
              ) : (
                <DoctIcon width="24" height="24" name="cancel" className="text-danger" />
              )
            }
            packageTitle={
              subscriberOrderDTO?.orderStatus === 'Confirmed'
                ? 'Subscription Details'
                : 'Payment Transaction Failed'
            }
            status={subscriberOrderDTO?.subscriber?.status}
            isActive={subscriberOrderDTO?.orderStatus === 'Confirmed' ? true : false}
            subscriptionPlanName={`${subscriberPlanHistory?.subscriptionPackageName} Subscription`}
            accordionBody={
              <Accordion title={`${subscriberPlanHistory?.name}`}>
                <div className="d-flex flex-column">
                  {/* coursre listing */}
                  {subscriberPlanHistory?.subscriptionPackageName === 'Institute Dashboard' && (
                    <div className={`m-0 text-grey-600 ml-2`}>
                      <li className={`m-0 text-grey-600`}>
                        <DoctIcon width="10" name="check" className="mr-2" fill="#707070" />
                        {subscriberPlanHistory?.courseApplicantMode === 'Unlimited'
                          ? 'Unlimited'
                          : subscriberPlanHistory?.noOfCourseApplicant}
                        &nbsp;Course Applicants Views
                      </li>
                      <li className={`m-0 text-grey-600`}>
                        <DoctIcon width="10" name="check" className="mr-2" fill="#707070" />
                        {subscriberPlanHistory?.courseMode === 'Unlimited'
                          ? 'Unlimited'
                          : subscriberPlanHistory?.noOfCourse}
                        &nbsp;Course listing
                      </li>
                    </div>
                  )}
                  {/* recruiter listing */}
                  {subscriberPlanHistory?.subscriptionPackageName === 'Recruiter Dashboard' && (
                    <div className={`m-0 text-grey-600 ml-2`}>
                      <li className={`m-0 text-grey-600`}>
                        <DoctIcon width="10" name="check" className="mr-2" fill="#707070" />
                        {subscriberPlanHistory?.jobMode === 'Unlimited'
                          ? 'Unlimited'
                          : subscriberPlanHistory?.purchasedPlanJobs}
                        &nbsp;Job Posting
                      </li>
                      <li className={`m-0 text-grey-600`}>
                        <DoctIcon width="10" name="check" className="mr-2" fill="#707070" />
                        {subscriberPlanHistory?.jobApplicantMode === 'Unlimited'
                          ? 'Unlimited'
                          : subscriberPlanHistory?.noOfJobApplicant}
                        &nbsp;Job Applicants Views
                      </li>
                    </div>
                  )}
                </div>
              </Accordion>
            }
          >
            {/* coursre listing */}
            {subscriberPlanHistory?.subscriptionPackageName === 'Institute Dashboard' && (
              <DoctTypography variant="subtitle3" className="m-0 mt-1 text-grey-600">
                {subscriberOrderDTO?.subscriber?.validity?.includes('-1 Lifetime')
                  ? 'Lifetime'
                  : subscriberOrderDTO?.subscriber?.validity}
              </DoctTypography>
            )}
            {/* recruiter listing */}
            {subscriberPlanHistory?.subscriptionPackageName === 'Recruiter Dashboard' && (
              <DoctTypography variant="subtitle3" className="m-0 mt-1 text-grey-600">
                {getSubscriptionText(subscriberPlanHistory)}
              </DoctTypography>
            )}
            <DoctTypography variant="subtitle3" className="m-0 text-grey-600">
              &nbsp; . &nbsp;
            </DoctTypography>
            <DoctTypography variant="subtitle3" className="m-0 mt-1 text-grey-600">
              {durationOfPlan}
            </DoctTypography>
          </CommonPackageDetailCard>
          <CommonBillingInfoCard
            orderDetails={data ?? {}}
            statusSuccess={subscriberOrderDTO?.orderStatus === 'Confirmed' ? true : false}
          />
        </DoctContainer>
        <div className={` ${style['subscription-modal-footer-confirm']} position-absolute w-100`}>
          <FooterCopyRight />
        </div>
      </EnterpriseFormLayout>
    </>
  );
};

export default ConfirmationScreen;
