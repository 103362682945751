import React from 'react';

import { DoctModal } from '@doct-react/app';
import { DoctTypography } from '@doct-react/core';

function DeleteCommittee({
  open,
  handleClose,
  handlePrimaryButtonClick,
  featureText,
  name,
  primaryBtnText,
}) {
  return (
    <DoctModal
      open={open}
      handleClose={handleClose}
      primaryBtnText={primaryBtnText}
      secondaryBtnText="Cancel"
      iconName=""
      className="delete_member_modal"
      width={328}
      handlePrimaryButtonClick={handlePrimaryButtonClick}
    >
      <DoctTypography variant="h6">
        Are you sure to {featureText === 'Designation' ? 'remove' : 'delete'} this “{featureText}”?
      </DoctTypography>

      <DoctTypography variant="subtitle3" className="text-grey-600">
        Know that {featureText} “{name}” will be{' '}
        {featureText === 'Designation' ? 'removed' : 'deleted'}, you cannot undo this action.
      </DoctTypography>
    </DoctModal>
  );
}

export default DeleteCommittee;
