import dayjs from 'dayjs';
import { BUILD_ID as APP_BUILD_ID } from '../build-id';
import { contacts } from '../utils/contact.js';
import { months } from '../features/InstituteDashboard/Courses/ManageCourse/components/static/monthOptions';

const LocalizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(LocalizedFormat);

export function filterNonNull(obj) {
  // eslint-disable-next-line no-unused-vars
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));
}

export function updateObjectInArray(array = [], obj = {}, findBy = 'id') {
  const index = array.findIndex((item) => item[findBy] === obj[findBy]);
  if (index !== -1) {
    const findObj = array[index];
    obj = { ...findObj, ...obj };
    array[index] = obj;
  } else {
    array.push(obj);
  }
  return array;
}

export function pick(object, keys) {
  return keys.reduce((obj, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
}

export function transformMenuItems(arrayProp, code, title, id) {
  if (!arrayProp && !Array.isArray(arrayProp) && !arrayProp?.length) return;

  const arrayOfPickedKey = arrayProp.map((element) => {
    return pick(element, [code, title, id]);
  });

  if (arrayOfPickedKey?.length) {
    return arrayOfPickedKey.reduce((array, obj, i) => {
      array.push({
        title: obj[title],
        index: i,
        value: obj?.id,
        id: obj?.id,
        code: obj?.code,
      });
      return array;
    }, []);
  }
}

export function getCookie(cname) {
  if (!process.browser) {
    return;
  }

  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
}

export function setCookie(cName, cValue, timePeriod, type) {
  let date = new Date();
  if (type === 'days') {
    date.setTime(date.getTime() + timePeriod * 24 * 60 * 60 * 1000);
  } else {
    date.setTime(date.getTime() + timePeriod);
  }
  const expires = 'expires=' + date.toUTCString();
  document.cookie = `${cName}=${cValue.toString()}; path=/; ${expires}`;
}

export function useHardReloadForCachedApp() {
  const BUILD_ID = getCookie('BUILD_ID');
  if (BUILD_ID) {
    if (APP_BUILD_ID == BUILD_ID) {
      return;
    }

    setCookie('OLD_BUILD_ID', BUILD_ID, 365, 'days');
    setCookie('BUILD_ID', APP_BUILD_ID, 365, 'days');
    window.location.replace(window.location.href);

    return;
  }
  setCookie('BUILD_ID', APP_BUILD_ID, 365, 'days');
}

export const clearCookieFromServer = () => {
  // Set the signal cookie
  document.cookie = `clearCookiesSignal=true; domain=.${'dochub.in'}; path=/;`;
};

export function uid() {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export function prepareAttendees(listOfAttendees = []) {
  const data = listOfAttendees.reduce((prevValue, objOfAttendee) => {
    let attendeesArray = prevValue;
    let attendeesObj = {};

    let ticketsObj = { ...objOfAttendee.ticketsOfAttendee };
    delete ticketsObj.prices;
    delete ticketsObj.currency;
    delete ticketsObj.numberOfSeats;
    delete ticketsObj.attendeeType;
    delete ticketsObj.description;

    attendeesObj = {
      ticket: ticketsObj,
      title: 0,
      gender: objOfAttendee.details?.gender,
      name: objOfAttendee.details?.name,
      country: objOfAttendee.details?.country.label,
      state: objOfAttendee.details?.state.label,
      city: objOfAttendee.details?.city.label,
      phoneNo: {
        countryCode: objOfAttendee.details?.mobileCountryCode.label,
        number: objOfAttendee.details?.mobileNumber,
      },
      whatsAppNumber: {
        number: objOfAttendee.details?.whatsAppNumber,
        countryCode: objOfAttendee.details?.whatsappCountryCode?.label,
      },
      emailId: objOfAttendee.details?.emailId,
    };

    attendeesArray.push(attendeesObj);

    return attendeesArray;
  }, []);

  return data;
}

export function prepareBillingDetails(billingDetails) {
  return {
    ...billingDetails,
    ...{
      name: billingDetails?.name,
      city: billingDetails?.city?.label,
      state: billingDetails?.state?.label,
      country: billingDetails?.country?.label,
      contactNo: {
        countryCode: billingDetails?.mobileCountryCode?.label,
        number: billingDetails?.mobileNumber,
      },
      whatsAppNumber: {
        countryCode: billingDetails?.whatsappCountryCode?.label,
        number: billingDetails?.whatsAppNumber,
      },
    },
  };
}

export function filterNull(obj = {}) {
  // eslint-disable-next-line no-unused-vars
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export function filterNullArrayOfObj(array = []) {
  const arrayFiltered = [];

  array.forEach((obj) => {
    arrayFiltered.push(filterNull(obj));
  });

  return arrayFiltered;
}

export function findDifferenceBetweenDates(startDate, endDate) {
  const date1 = dayjs(startDate.split('T')[0]);
  const date2 = dayjs(endDate.split('T')[0]);
  return Math.ceil(date2.diff(date1, 'day', true));
}

export function imageHandler(src) {
  if (typeof src == 'string') {
    return src;
  } else if (src && src[0] != undefined) {
    return window.URL.createObjectURL(src[0]);
  } else if (src?.length != 0 && src) {
    return window.URL.createObjectURL(src);
  }
}

export function scheduleFromStartDateEndDate(startDate, endDate) {
  return Array.from({ length: findDifferenceBetweenDates(startDate, endDate) + 1 }, (_, i) => ({
    day: `Day ${i + 1}`,
    key: `day${i + 1}`,
    date:
      i == 0
        ? dayjs(startDate).format('dddd, D MMMM YYYY')
        : dayjs(dayjs(startDate).add(i, 'day')).format('dddd, D MMMM YYYY'),
  }));
}

export const checkKeyDownAndPreventDefault = (e) => {
  if (e.key === 'Enter') e.preventDefault();
};

export const prepareUploadedFile = (fileUrl, uploadedFile) => {
  if (fileUrl) {
    return [
      {
        name: fileUrl?.substring(fileUrl?.lastIndexOf('/') + 1),
      },
    ];
  }
  if (uploadedFile?.length) return uploadedFile;
  return [];
};

export function pageLoad() {
  const loaderEl = document.getElementById('loader');
  if (loaderEl) loaderEl.style.display = `none`;
}

export function scrollToEl(elementSelector) {
  const el = document?.querySelector(elementSelector);

  if (!el) return;
  const pos = +el.offsetTop;
  scrollTo(0, pos);
}

const crypto = require('crypto');

const secretKey = 'plandata';

export const encryptData = (data) => {
  const cipher = crypto.createCipher('aes-256-cbc', secretKey);
  let encrypted = cipher.update(JSON.stringify(data), 'utf-8', 'hex');
  encrypted += cipher.final('hex');
  return encrypted;
};

export const decryptData = (encryptedData) => {
  const decipher = crypto.createDecipher('aes-256-cbc', secretKey);
  let decrypted = decipher.update(encryptedData, 'hex', 'utf-8');
  decrypted += decipher.final('utf-8');
  return JSON.parse(decrypted);
};

export const needAssitanceInfoInSubscription = (route) => {
  if (+route === 1) {
    return contacts.institute;
  }
  return contacts.recruiter;
};
export const createHtmlContent = (currentPosition, sampleText, fontSize) => {
  return `
  <html>
          <head>
            <title>Certificate</title>
            <style>
            body {
                position: relative;
                margin: 0;
                padding: 0;
            }
            img {
                height: auto;
                width: auto;
                // max-width: 866px !important;
            }
            .text-placeholder {
                position: absolute;
                font-size: ${fontSize}px; 
                left: ${currentPosition.x}px !important;
                top: ${currentPosition.y}px !important;
                font-weight: 900;
                font-size: x-large;
            }   
            .position-relative{
              position: relative;
            }         
            </style>         
          </head>
          <body>
           <div class='position-relative'>
            <img src='{{image}}' alt='Certificate Image' loading='eager'/>
          
            <div class='text-placeholder'>${sampleText}</div></div>
          </body>
        </html>
      `;
};

export const downloadHtml = (datas) => {
  const { images } = datas ?? {};
  if (images?.length) {
    let htmlContent = createHtmlContent(
      datas?.images[0],
      datas?.text,
      datas?.position,
      datas?.sampleText,
      datas?.fontSize,
      datas?.currentPosition,
    );
    const blob = new Blob([htmlContent], { type: 'text/html' });
    return blob;
  }
  return null; // Return null if no images are provided or the images array is empty
};

export function formatDate(date) {
  if (typeof date !== 'undefined') return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
  return null;
}

export function manageColumnData(el, column) {
  const value = column[el.key];

  if (el.isDate && value != null) {
    return dayjs(value).format('DD MMM YYYY');
  }

  switch (el.key) {
    case 'status':
      return value === 'Created' ? 'Pending' : value;
    case 'memberStatus':
      return value === 'RenewalPending' ? 'Renewal Pending' : value;
    default:
      if (column.registrationType === 'Online' || column.registrationType === 'Complementary') {
        return value;
      } else {
        return value ? value : '';
      }
  }
}

export function formatDateOfAwardee(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export function fileConvertionInBytesToMb(uploadedFile) {
  const fileSizeInMB = uploadedFile.size / (1024 * 1024); // Convert bytes to MB
  return fileSizeInMB;
}

// Function to get the MIME type based on the file header
export const getMimeType = (file) => {
  if (!file || file.length < 4) {
    console.error('File is either not provided or too short');
    return 'unknown';
  }

  const fileHeader = new Uint8Array(file.slice(0, 4));
  const headerString = fileHeader.reduce(
    (str, byte) => str + byte.toString(16).padStart(2, '0'),
    '',
  );

  switch (headerString) {
    case '25504446':
      return 'application/pdf';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
      return 'image/jpeg';
    case '89504e47':
      return 'image/png';
    case '504b0304':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    default:
      console.error('Unknown file header:', headerString);
      return 'unknown';
  }
};

export const readFileAsArrayBuffer = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

const contactInfo = `
  <br />
  <strong>Email:</strong> <a href="mailto:enterprise@docthub.com">enterprise@docthub.com</a>
  <br />
  <strong>Phone:</strong> <a href="tel:+919090902626">+91 90909 02626</a>
  `;

const Enum = {
  PostAJob: 'Post A Job',
  ListACourse: 'List A Course',
  CreateAnEvent: 'Create An Event',
  ManageMemberships: 'Manage Memberships',
};

const unAuthMsg = `You Don't have authority to do. Please contact our team:<br />${contactInfo}`;
const editMsg = `You cannot edit this. To edit, Please contact our team:<br />${contactInfo}`;
const previewMsg = `You cannot preview this. To preview, Please contact our team:<br />${contactInfo}`;

const dynamicTypedMsg = (type, msgFor) => {
  return `Your Enterprise type is <b>${type}</b>. If you want to “${msgFor}”.<br /> please get in touch with our team:<br />${contactInfo}`;
};

export const descriptionContent = (type, actualType) => {
  if (type.includes('recruiter')) {
    if (
      type.includes('job-applicant') ||
      type.includes('job-submitted') ||
      type.includes('job-failed')
    ) {
      return unAuthMsg;
    } else if (type.includes('post-a-job')) {
      return dynamicTypedMsg(Enum[actualType], 'Post a Job');
    } else if (type.includes('edit')) {
      return editMsg;
    } else if (type.includes('job-preview')) {
      return previewMsg;
    }
  } else if (type.includes('institute')) {
    if (type.includes('applicants')) {
      return unAuthMsg;
    } else if (type.includes('add-course')) {
      return dynamicTypedMsg(Enum[actualType], 'List a Course');
    } else if (type.includes('edit')) {
      return editMsg;
    } else if (type.includes('preview-course')) {
      return previewMsg;
    }
  } else if (type.includes('events')) {
    if (
      type.includes('manage-registration') ||
      type.includes('invoice-summary') ||
      type.includes('manage-attendees') ||
      type.includes('abstracts')
    ) {
      return unAuthMsg;
    } else if (type.includes('new')) {
      return dynamicTypedMsg(Enum[actualType], 'Create Event');
    } else if (type.includes('edit')) {
      return editMsg;
    }
  } else if (type.includes('new-memberships')) {
    return dynamicTypedMsg(Enum[actualType], 'New Membership');
  } else if (type.includes('edit-membership')) {
    return editMsg;
  } else if (type.includes('manage-memberships')) {
    if (
      type.includes('manage-directory') ||
      type.includes('activity-log') ||
      type.includes('committee')
    ) {
      return unAuthMsg;
    }
  }
};
